import { z } from 'zod';
import { getHints } from '~/utils/client-hints';

export type RootLoaderReturn = {
  user: {
    id: string;
    name: string | null;
    type: 'fake' | 'real';
    email: string;
    key: string;
    hourlyRate: number | null;
  } | null;
  requestInfo: {
    hints: ReturnType<typeof getHints>;
    origin: string;
    path: string;
    userPrefs: {
      // theme: 'light' | 'dark';
    };
  };
};

export const PasswordSchema = z
  .string({ required_error: 'Password is required' })
  .min(6, { message: 'Password is too short' })
  .max(100, { message: 'Password is too long' });
export const NameSchema = z
  .string({ required_error: 'Name is required' })
  .min(3, { message: 'Name is too short' })
  .max(40, { message: 'Name is too long' });
export const EmailSchema = z
  .string({ required_error: 'Email is required' })
  .email({ message: 'Email is invalid' })
  .min(3, { message: 'Email is too short' })
  .max(100, { message: 'Email is too long' })
  // users can type the email in any case, but we store it in lowercase
  .transform(value => value.toLowerCase());

export const redirectToKey = 'redirectTo';

export type Site = {
  id: string;
  url: string;
  faviconUrl: string | null;
};
export type Goal = {
  site: Site;
  goalHours: number;
};

export type GoalTimeSpent = {
  goal: Goal;
  timeSeconds: number;
};
export type DashboardData = { goals: GoalTimeSpent[]; startDate: string; endDate: string; dailyUsage: DailyUsageData };

export type DailyUsageData = {
  usages: {
    site: Site;
    timeSeconds: number;
    date: string;
  }[];
};
